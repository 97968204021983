import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { Preferences } from '@capacitor/preferences';

import '@/utils/configs/locales/i18n';
import '@/utils/configs/axiosConfig';
import { storageKeys } from '@/consts/storageKeys.ts';
import { useGuestAuth } from '@/modules/users/api/useGuestAuth.ts';
import analytics from '@/utils/analytics';
import { isBotVisitor } from '@/utils/isBotVisitor.ts';

import 'dayjs/locale/ru';
import 'dayjs/locale/kk';

type ILocalContextValues = {
  token?: string | null;
  user?: object | null;
};

export const LocalContext = createContext<ILocalContextValues>({
  token: undefined,
  user: undefined,
});

export const LocalContextProvider: FC<{ children?: ReactNode }> = (props) => {
  // const visitor = useVisitorData({ extendedResult: true, ignoreCache: false }, { immediate: true });
  const [token, setToken] = useState<ILocalContextValues['token']>();
  const guestAuth = useGuestAuth();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  // useEffect(() => {
  //   if (visitor.data?.visitorId) {
  //     Preferences.set({ key: storageKeys.fingerPrint, value: visitor.data.visitorId });
  //     guestAuth.mutateAsync({ fingerPrint: visitor.data?.visitorId }).then((res) => {
  //       if (res.headers?.['access_token']) {
  //         setToken(res.headers?.['access_token']);
  //         Preferences.set({ key: storageKeys.token, value: res.headers?.['access_token'] });
  //       }
  //     });
  //     analytics.identify(visitor.data.visitorId);
  //   }
  // }, [visitor.data?.visitorId]);

  useEffect(() => {
    setTimeout(async () => {
      if (!isBotVisitor) {
        const FingerprintJS = (await import('@fingerprintjs/fingerprintjs')).default;
        FingerprintJS.load().then(async (fp) => {
          const { value } = await Preferences.get({ key: storageKeys.fingerPrint });

          let fingerPrint = value;

          if (!fingerPrint) {
            const { visitorId } = await fp.get();
            fingerPrint = visitorId;
          }

          if (fingerPrint) {
            Preferences.set({ key: storageKeys.fingerPrint, value: fingerPrint });
            guestAuth.mutateAsync({ fingerPrint }).then((res) => {
              if (res.headers?.['access_token']) {
                setToken(res.headers?.['access_token']);
                Preferences.set({ key: storageKeys.token, value: res.headers?.['access_token'] });
              }
            });
            analytics.identify(fingerPrint);
          }
        });
      }
    }, 5500);

    Preferences.get({ key: storageKeys.fingerPrint }).then((res) => setToken(res.value));
    analytics.init();
  }, []);

  return (
    <LocalContext.Provider
      value={{
        token,
        user: null,
      }}
    >
      {props.children}
    </LocalContext.Provider>
  );
};

export const useLocalContext = () => {
  const localContext = useContext(LocalContext);

  if (typeof localContext === 'undefined') {
    throw new Error('useLocalContext must be used within a LocalContextProvider');
  }

  return localContext;
};
